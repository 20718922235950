import React from "react"
import Tilty from "../components/tilty";

import { graphql } from "gatsby"


import Layout from "../components/layout"

// import Image from "../components/image"
import Img from "gatsby-image"

import SEO from "../components/seo"

// import margarethowell from '../images/margaret-howell.jpg'

export default ({ data }) => {

  console.log(data)

  const projects = data.allWpProject.edges

  return (
    

    <Layout>
    <SEO title="Home" />

    <h3 className="work" style={{ textAlign: `center`}}>Selected Work</h3>

    { projects.map((el, i) => (
      <div key={i}>

      <Tilty
        data-tilt-reverse="true"
        data-tilt-axis="x"
        settings = {{
          scale: 1.1,
          perspective: 900,
          reset: true
        }}>
        <div className="project" style={{ textAlign: `center`}}>
          <div className="project__info">
            <a className="project__info--link" href={ el.node.acf.url } target="_blank" rel="noopener noreferrer">
              <h2>{ el.node.title }</h2>
            </a>

            <span className="project__info--job">Development &mdash; </span><span className="project__info--type">{ el.node.acf.development }</span>
            <span className="project__info--location">{ el.node.acf.location }</span>
            <a className="project__info--url" href={ el.node.acf.url } target="_blank" rel="noopener noreferrer">{ el.node.acf.linkName }</a>
          </div>
        
            {/* <img className="project__image" src={margarethowell} alt="Margaret Howell" /> */}
            <Img
              className="project__image"
              fluid={
              el.node.acf.image.localFile.childImageSharp.fluid
              }
              alt={el.node.title}
            />
        </div>
      </Tilty> 

      </div>
    ))}

    <div style={{
      marginBottom: `1.45rem`,
      height: `100vh`,
      display: `flex`,
      alignItems: `center`,
      justifyContent: `center`,
    }}>
      <div style={{ textAlign: `center` }}>
        <p>I’m a Web Developer currently based in Melbourne with over 11 years of agency experience.<br/>
        I specialise in custom built WordPress Backends and online stores in Shopify and WooCommerce</p>
        <p>Contributing to the internet since 2011</p>
        <br/>
        <span className="contact" style={{ textAlign: `center` }}>GET IN TOUCH</span>
        <ul>
          <li>
            <a href="mailto:info@rowanedward.com?subject=Website Development&body=Hello,">email</a>
          </li>
          <li>  
            <a href="https://github.com/rowanedward" rel="noopener noreferrer" target="_blank">GitHub</a>
          </li>
        </ul>
      </div>
    </div>

  </Layout>

  )
}

export const pageQuery = graphql`
  query {
    allWpProject(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          acf {
            url
            location
            development
            linkName
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1700, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`